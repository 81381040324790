<template>
  <div>
    <div class="banner">
      <el-carousel indicator-position="none" arrow="never">
        <el-carousel-item height="650" v-for="item in bannerImg" :key="item">
          <img :src="item" :alt="item" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="contBox1">
      <div class="contBox1Top">
        <div class="contBox1TopR">
          <div style="font-size: 36px; font-family: 'OPPOSans-H'">
            {{ basicInfo.title }}
          </div>
          <p>{{ basicInfo.text }}</p>
        </div>
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in caseList" :key="item.id">
            <div class="swiperItem">
              <div class="swiperImg">
                <img :src="item.icon" alt="" />
              </div>
              <div class="swiperName">{{ item.name }}</div>
              <div class="overlayItem">
                <div class="name">{{ item.name }}</div>
                <div class="cont">{{ item.introduction }}</div>
                <div class="more" @click="showMore(item.id)">查看更多</div>
              </div>
            </div>
          </div>
        </div>
        <div class="btnBox">
          <!-- Add Pagination -->
          <div class="angle swiper-button-next"></div>
          <div class="angle swiper-button-prev"></div>
        </div>
      </div>
      <!-- <el-carousel type="card" height="400px" :autoplay="false">
        <el-carousel-item v-for="item in caseList" :key="item.id">
          <div>
            <div class="pic">
              <img :src="item.icon" alt="" />
            </div>
            <h3>{{ item.name }}</h3>
            <p>{{ item.introduction }}</p>
            <el-button @click="programs (item.id)" round>了解更多</el-button>
          </div>
        </el-carousel-item>
      </el-carousel> -->
      <!-- <div class="panels">
        
          实际图片使用 :style="{'background':'url('+item.banner+')'}" 
          效果图片使用 :style="{'background':'url('+testUrl+')'
       
        <div
          class="panel"
          v-for="item in caseList"
          :key="item.id"
          :style="{ background: 'url(' + item.banner + ')' }"
        >
          <h2>{{ item.name }}</h2>
          <div class="divid"></div>
          <p>{{ item.introduction }}</p>
          <button @click="programs (item.id)">查看更多</button>
        </div>
      </div> -->
    </div>
    <div class="contBox2">
      <div
        style="font-size: 36px; padding-top: 104px; font-family: 'OPPOSans-H'"
      >
        合作流程
      </div>
      <div class="contBox2Top">
        <img
          style="width: 926px; height: 637px"
          src="../../../public/img/customService/bgcImg.png"
          alt=""
        />
        <div class="imgLeft">
          <img
            v-for="item in contBox2Imgs1"
            :key="item.value"
            :src="
              require(`../../../public/img/customService/${item.value}.png`)
            "
            :alt="item.value"
          />
        </div>
        <div class="imgRight">
          <img
            v-for="item in contBox2Imgs2"
            :key="item.value"
            :src="
              require(`../../../public/img/customService/${item.value}.png`)
            "
            :alt="item.value"
          />
        </div>
      </div>
    </div>
    <div class="contBox2Bot">
      <div style="font-size: 36px; font-family: 'OPPOSans-H'">客户案例</div>
      <!-- <h3>客户案例</h3> -->
      <div class="caseBox">
        <div
          v-for="item in caseItem"
          @click="customerCaseDetails(item)"
          :key="item.id"
        >
          <img :src="item.screenPhoto" :alt="item.name" />
          <div>
            <div class="h5">{{ item.name }}</div>
            <p>{{ item.caseCategoryName }}</p>
          </div>
        </div>
      </div>
      <p>
        <el-button
          style="background-color: #2c7bec"
          size="none"
          round
          @click="goPrograms"
          >更多案例<i class="el-icon-right"></i
        ></el-button>
      </p>
    </div>
    <div class="contBox3">
      <div class="contBox3Top">
        <!-- <section>
          <h3>{{ SolutionTitle }}</h3>
          <p>{{ SolutionIntroduction }}</p>
          <el-button type="info" @click="moreSolutions" plain
            >查看更多</el-button
          >
          <div>
            <el-button
              @click="solutionDetails(item)"
              v-for="item in showCaseList"
              :key="item.id"
              type="text"
              >{{ item.name }}</el-button
            >
          </div>
        </section> -->
        <div class="itemBox">
          <div
            v-for="item in showCaseList"
            @click="solutionDetails(item)"
            :key="item.id"
          >
            <h5>{{ item.name }}</h5>
            <p>{{ item.introduction }}</p>
          </div>
        </div>
        <p></p>
      </div>
      <div class="contBox3Bot">
        <div class="h3">合作伙伴</div>
        <!-- <h3>合作伙伴</h3> -->
        <div class="w">
          <div
            class="item"
            v-for="item in CooperativePartnerImgs"
            :key="item.id"
          >
            <img :src="item.image" :alt="item.name" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // findCompanyDetails,
  getCaseList,
  getBasicInfo,
  getCaseClassification,
  // getRotationCase,
  getSolutionDetails,
  getSolutionList,
  getCooperativePartner
} from '../../api/customService'
import Swiper from 'swiper'
import '@/assets/css/swiper.min.css'
export default {
  data() {
    return {
      testUrl: require('../../assets/imgs/test/bg.jpg'),
      bannerImg: '', //banner图
      basicInfo: {}, // 请求的基本信息
      contBox1TopH: '了解我们的产品',
      caseList: [],
      contBox2Imgs1: [
        { value: 'Group1' },
        { value: 'Group2' },
        { value: 'Group3' },
        { value: 'Group4' }
      ],
      contBox2Imgs2: [
        { value: 'Group5' },
        { value: 'Group6' },
        { value: 'Group7' }
      ],
      caseItem: [],
      SolutionTitle: '解决方案',
      SolutionIntroduction: '测试文本123459',
      showCaseList: [],
      illustration: '',

      CooperativePartnerImgs: [],
      mySwiper: null
    }
  },
  created() {
    this._initData()
  },
  methods: {
    showMore(id) {
      this.$router.push(`/programs/${id}`)
    },
    _initData() {
      // 基本信息
      getBasicInfo().then((res) => {
        if (res.status == 200) {
          this.basicInfo = res.data
          this.bannerImg = res.data.banner.split(',')
        }
      })
      // 案列种类
      getCaseClassification({
        data: {},
        page: 1,
        size: 20,
        start: 1
      }).then((res) => {
        if (res.status == 200) {
          this.caseList = res.data
          // res.data.forEach(item=>{
          //   console.log('scbjn',item)
          //     // item.screenPhoto = item.detailPicture.split(",")[0]
          // })
          this.$nextTick(() => {
            // DOM更新了
            // swiper重新初始化
            /* eslint-disable no-new */
            this.mySwiper = new Swiper('.swiper-container', {
              pagination: '.swiper-pagination',
              slidesPerView: 4,
              spaceBetween: 40,
              grabCursor: true,
              nextButton: '.swiper-button-next',
              prevButton: '.swiper-button-prev',
              breakpoints: {
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10
                }
              }
            })
          })
        }
      })
      // 客户案例
      getCaseList({
        page: 1,
        size: 8,
        start: 0
      }).then((res) => {
        if (res.status == 200) {
          this.caseItem = res.data.rows
          res.data.rows.forEach((item) => {
            item.screenPhoto = item.detailPicture.split(',')[0]
          })
        }
      })
      // 分组获取轮播客户案例
      // getRotationCase({ size: 8 }).then((res) => {
      //   if (res.status == 200) {
      //     console.log('分组客户案例',res.data);
      //     this.caseItem = res.data;
      //   }
      // });
      // 所有可展示的解决方案
      getSolutionList().then((res) => {
        if (res.status == 200) {
          res.data.splice(5, res.data.length - 6)
          // console.log(res.data);
          this.showCaseList = res.data
        }
      })
      // 获取解决方案
      getSolutionDetails().then((res) => {
        if (res.status == 200) {
          this.SolutionTitle = res.data.title
          this.SolutionIntroduction = res.data.introduction
          this.illustration = res.data.illustration
        }
      })

      // 获取合作伙伴
      getCooperativePartner().then((res) => {
        if (res.status == 200) {
          this.CooperativePartnerImgs = res.data.cooperativePartnerList
        }
      })
    },
    // 了解更多案例分类
    moreSolutions() {
      this.$router.push({ path: `/moreSolutions` })
    },
    // 更多客户案例跳转
    programs() {
      this.$router.push({ path: `/programs` })
    },
    customerCaseDetails(item) {
      // console.log(item);
      this.$router.push({
        path: `/categoryCaseDetails/更多种类/${item.caseCategoryId}/${item.id}`
      })
    },
    goPrograms() {
      console.log(33333)
      this.$router.push({ path: `/programs` })
    },
    solutionDetails(item) {
      console.log(item)
      this.$router.push({ path: `/schemeDetails/${item.id}` })
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  text-align: center;
  ::v-deep .el-carousel > .el-carousel__container {
    height: 650px !important;
    .el-carousel__item {
      width: 100%;
      text-align: center;
      > img {
        width: 100%;
        height: 650px;
        object-fit: cover;
      }
    }
  }
}
.contBox1 {
  width: 1200px;
  margin: 104px auto 187px;
  > .el-carousel {
    width: 100%;
    margin: auto;

    > .el-carousel__container {
      > .el-carousel__item {
        > h3 {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        > p {
          display: -webkit-box; //对象作为弹性伸缩盒子模型显示
          overflow: hidden; //溢出隐藏
          -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的排列方式
          -webkit-line-clamp: 3; //设置 块元素包含的文本行数
        }
      }
    }
  }
  .contBox1Top {
    width: 1200px;
    display: flex;
    justify-content: centern;
    align-items: center;
    margin-bottom: 152px;
    .contBox1TopR {
      width: 100%;
      text-align: center;
      padding: 0 117px;
      h2 {
        // height: 68px;
        font-weight: 700;
        font-family: 'OPPOSans-H';
        font-size: 42px;
        line-height: 61px;
        color: #515151;
        margin: 15px 0px;
        display: -webkit-box; //对象作为弹性伸缩盒子模型显示
        overflow: hidden; //溢出隐藏
        -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的排列方式
        -webkit-line-clamp: 2; //设置 块元素包含的文本行数
      }
      p {
        scrollbar-width: none;
        // overflow: scroll;
        font-family: 'OPPOSans-M';
        font-size: 18px;
        line-height: 40px;
        color: rgba(128, 128, 128, 1);
        // padding: 20px 0;
        padding-top: 40px;
        margin: 0;
        display: -webkit-box; //对象作为弹性伸缩盒子模型显示
        overflow: hidden; //溢出隐藏
        -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的排列方式
        -webkit-line-clamp: 3; //设置 块元素包含的文本行数
      }
    }
  }
  .contBox1Bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 350px;
    margin-top: 100px;
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: flex-start;

    // 滚动条
    &::-webkit-scrollbar {
      display: none;
    }
    > div {
      width: 150px;
      height: 300px;
      justify-content: center;
      margin: 0px 25px;
      box-sizing: border-box;
      > img {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        margin: auto;
      }
      .pic {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        background: rgba(0, 145, 255, 0.1);
        padding: 10px;
        box-sizing: border-box;
        margin: 0 auto;
      }
      .pic img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
      > h6 {
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
        margin: 25px 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #666666;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > button {
        width: 100px;
        height: 32px;
        margin-top: 20px;
      }
    }
  }

  .panels {
    width: 100%;
    height: 400px;
    display: flex;

    .panel {
      flex: 1;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      cursor: pointer;
      transition: all 0.3s linear;
      position: relative;
      // border-radius: 30px;
      // margin-right: 20px;
      // border-radius: 40px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      h2 {
        position: absolute;
        margin: 0;
        bottom: 30px;
        left: 20px;
        font-size: 36px;
        font-weight: 700;
        color: #fff;
        transition: all 0.5s linear;
      }
      .divid {
        position: absolute;
        bottom: 0;
        left: 20px;
        opacity: 0;
        transition: all 0.5s 0.1s linear;
      }
      p {
        text-align: left;
        position: absolute;
        bottom: 0;
        left: 20px;
        opacity: 0;
        transition: all 0.5s 0.2s linear;
        width: 100%;
        padding-right: 100px;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 22px;
        font-weight: 700;
        color: #fff;
      }
      button {
        padding: 10px 20px;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        opacity: 0;
        left: 20px;
        background-color: #74ebd5;
        background-image: linear-gradient(90deg, #74ebd5 0%, #9face6 100%);
        border: 0;
        outline: none;
        border-radius: 20px;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        transition: all 0.5s 0.3s linear;
      }

      &:hover {
        flex: 3;
        h2 {
          bottom: 180px;
        }
        .divid {
          width: 70px;
          height: 6px;
          border-radius: 4px;
          background-color: #08aeea;
          background-image: linear-gradient(80deg, #08aeea 0%, #2af598 100%);
          bottom: 160px;
          opacity: 1;
        }
        p {
          bottom: 80px;
          opacity: 1;
        }
        button {
          bottom: 20px;
          opacity: 1;
        }
      }
      // p{
      //   opacity: 0;
      //   position: absolute;
      //   z-index: 3;
      //   transition: all .3s linear;
      //   color: #fff;
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }
      // p:nth-child(1){
      //   width: 160px;
      //   top: -40px;
      //   left: 20px;
      //   font-size: 38px;
      //   font-weight: 700;
      //   text-align: left;
      //   transition: all .2s linear;
      // }
      // &:hover p:nth-child(1){
      //   opacity: 1;
      //   top: 10px;
      // }
      // p:nth-child(2){
      //   width:360px;
      //   bottom: -10px;
      //   right: 10px;
      //   font-size: 24px;
      //   font-weight: 700;
      //   text-align:right;
      //   transition: all .5s .2s linear;
      // }
      // &:hover p:nth-child(2){
      //   opacity: 1;
      //   right: 10px;
      //   bottom: 90px;
      // }
      // p:nth-child(3){
      //   padding: 10px 20px;
      //   box-sizing: border-box;
      //   border: 1px solid #eee;
      //   border-radius: 40px;
      //   right: -10px;
      //   bottom: 10px;
      //   font-size: 20px;
      //   font-weight: 700;
      //   transition: all .5s .3s linear;
      // }
      // &:hover p:nth-child(3){
      //   opacity: 1;
      //   right: 50px;
      //   bottom: 10px;
      // }
    }
  }
}
.contBox2 {
  // background: pink; /* 针对不支持渐变的浏览器 */
  // linear-gradient(#ecf7ff,#ecf7ff,#fff)
  // background: -webkit-linear-gradient(#ecf7ff, #ecf7ff, #fff);
  /*  Safari 5.1 到 6.0 */
  // background: -o-linear-gradient(#ecf7ff, #ecf7ff, #fff);
  // /*  Opera 11.6 到 12.0 */
  // background: -moz-linear-gradient(#ecf7ff, #ecf7ff, #fff);
  /*  Fx 3.6 到 15 */
  // background: linear-gradient(#ecf7ff, #ecf7ff, #fff);
  background: linear-gradient(
    180deg,
    #edf4ff 0%,
    rgba(237, 244, 255, 0.47) 100%
  );
  h2 {
    height: 68px;
    font-weight: 400;
    font-family: 'OPPOSans-H';
    font-size: 42px;
    line-height: 63px;
    color: #000000;
    padding-top: 65px;
  }
  .contBox2Top {
    width: 1200px;
    position: relative;
    margin: 116px auto 26px;

    > div {
      display: flex;
      flex-direction: column;
      position: absolute;
      > img {
        width: 290px;
      }
    }
    > .imgLeft {
      top: -80px;
      left: -50px;
    }
    .imgRight {
      top: 200px;
      right: -50px;
    }
  }
}
.contBox2Bot {
  width: 1200px;
  margin: 104px auto 129px;
  > h3 {
    font-style: normal;
    font-weight: 400;
    font-family: 'OPPOSans-H';
    font-size: 42px;
    text-align: start;
    color: #333333;
    margin-bottom: 60px;
    position: relative;
  }
  > h3::after {
    content: '';
    display: block;
    width: 70px;
    height: 4px;
    background-color: #484848;
    position: absolute;
    left: 0px;
    bottom: -20px;
  }
  .caseBox {
    display: flex;
    flex-wrap: wrap;
    padding: 80px 0;
    > div {
      width: 260px;
      height: 210px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      margin-right: 40px;
      margin-bottom: 30px;
      transition: all linear 0.3s;
      > img {
        width: 260px;
        height: 210px;
        border-radius: 16px;
        object-fit: cover;
      }
      &:hover {
        transform: scale(1.1);

        > div {
          opacity: 1;
        }
      }
      > div {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        position: absolute;
        top: 0;
        opacity: 0;
        color: #fff;
        // background: linear-gradient(56deg, rgb(255, 255, 255) 0%, rgb(132, 183, 237) 50%, rgb(255, 235, 211) 100%)
        background: linear-gradient(
          175deg,
          transparent 0%,
          transparent 50%,
          rgba(8, 103, 235, 0.902) calc(50% + 1px)
        );
        transition: all linear 0.3s;
      }
      > div > .h5 {
        text-align: center;
        font-family: 'OPPOSans-M';
        font-size: 22px;
        margin: 0;
        margin-top: 125px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > div > p {
        text-align: center;
        font-family: 'OPPOSans-M';
        font-size: 14px;
        margin: 0;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  > p {
    width: 1200px;
    text-align: center;
    margin: 0;
    button {
      width: 150px;
      border-radius: 30px;
      padding: 15px 45px 15px 15px;
      color: #fff;
      font-family: 'OPPOSans-M';
      font-size: 16px;
      position: relative;
      > span {
        > i {
          padding: 10px;
          border-radius: 50%;
          color: rgba(44, 123, 236, 1);
          background: #fff;
          position: absolute;
          right: 8px;
          bottom: 5px;
        }
      }
    }
  }
}
.contBox3 {
  width: 100%;
  padding-bottom: 60px;
  background: pink; /* 针对不支持渐变的浏览器 */
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
  /*  Safari 5.1 到 6.0 */
  background: -o-linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
  /*  Opera 11.6 到 12.0 */
  background: -moz-linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
  /*  Fx 3.6 到 15 */
  background: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
  .contBox3Top {
    width: 100%;
    height: 860px;
    padding-top: 160px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    background: url('../../../public/img/customService/programmeImg.png')
      no-repeat center center;
    > .itemBox {
      width: 1200px;
      // height: 800px;
      margin: auto;
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      > div {
        width: 340px;
        height: 172px;
        text-align: center;
        padding: 20px;
        margin: 10px 32px;
        box-sizing: border-box;
        cursor: pointer;
        background: url('../../../public/img/customService/Subtract.png');
        transition: all linear 0.3s;
        &:hover {
          transform: scale(1.2);
        }
        > h5 {
          text-align: start;
          width: 260px;
          color: rgba(255, 255, 255, 1);
          font-family: 'OPPOSans-B';
          font-size: 20px;
          margin: 0px 0 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > p {
          text-align: start;
          width: 260px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 24px;
          font-family: 'OPPOSans-R';
          font-size: 16px;
          margin: 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //从第3行开始隐藏
        }
      }
    }
    > p {
      width: 310px;
      height: 351px;
      background: url('../../../public/img/customService/lamp.png') no-repeat
        center center;
      position: absolute;
      left: 50%;
      top: 280px;
      margin-left: -155px;
      &:hover {
        background: url('../../../public/img/customService/bright.png')
          no-repeat;
      }
    }
    > section {
      width: 435px;
      text-align: start;
      > h3 {
        font-weight: 700;
        font-family: 'OPPOSans-H';
        font-size: 42px;
        color: #333333;
        position: relative;
        text-align: start;
        margin: 55px 0 80px;
      }
      > h3::after {
        content: '';
        display: block;
        width: 70px;
        height: 4px;
        background: #484848;
        position: absolute;
        left: 0;
        bottom: -30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > p {
        overflow: scroll;
        scrollbar-width: none;
        height: 190px;
        font-weight: 400;
        text-align: start;
        font-size: 18px;
        line-height: 31px;
        color: rgba(102, 102, 102, 0.85);
        margin-bottom: 50px;
        letter-spacing: 2px;
      }
      > button {
        font-weight: 700;
        font-size: 22px;
        color: #fff;
        background: #808080;
        border-radius: 28px;
        padding: 10px 20px;
        box-sizing: border-box;
      }
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 50px;
        > .el-button {
          color: #484848;
          font-size: 22px;
          font-weight: 400;
          margin: 10px;
          ::v-deep > span {
            width: 120px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              // font-weight: bolder;
              text-shadow: 0px 0px 1px #484848;
            }
          }
        }
      }
    }
  }
  .contBox3Bot {
    width: 1200px;
    margin: 104px auto 44px;
    > .h3 {
      color: rgba(0, 0, 0, 0.85);
      font-family: 'OPPOSans-H';
      font-size: 36px;
      display: block;
      text-align: center;
      padding-bottom: 80px;
    }
    > div {
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      .item {
        width: 280px;
        height: 88px;
        border-radius: 8px;
        overflow: hidden;
        transition: all linear 300ms;
        margin: 0 30px 30px 0;
        cursor: pointer;
        border: 0;
        outline: 0;
      }
      .item:hover {
        transform: scale(1.1);
      }
      > img {
        width: 100%;
        height: 100%;
        // width: 255px;
        justify-content: flex-start;
        align-items: center;
        > img {
          width: 255px;
          margin-right: 50px;
        }
      }
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 270px;
}
.swiperItem {
  position: relative;
  height: 100%;
  .swiperImg {
    height: 100%;
    position: relative;

    img {
      border-radius: 16px;
    }
  }
  .swiperImg::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.1);
  }
  .swiperName {
    position: absolute;
    bottom: 40px;
    left: 40px;
    color: #fff;
    font-size: 20px;
    font-family: 'OPPOSans-M';
  }
}
.swiperItem:hover {
  .swiperName {
    opacity: 0;
  }
  .overlayItem {
    // top: 0;
    // left: 0;
    transform: translateY(-100%);
    // background-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    color: rgb(255, 255, 255);
  }
}
.overlayItem {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  z-index: 2;
  // top: 0;
  transition: all 0.5s linear;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .name {
    font-family: 'OPPOSans-B';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
  }
  .cont {
    width: 214px;
    height: 84px;
    font-family: 'OPPOSans-M';
    overflow: hidden;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: rgba(255, 255, 255, 0.85);
  }
  .more {
    width: 100px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border: 1px solid #ffffff;
    border-radius: 100px;
    font-family: 'OPPOSans-B';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
  }
}

.btnBox {
  opacity: 0;
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: 9;
  // .angle{
  //   width: 20px;
  //   height: 20px;
  //   background-color: red;
  // }
}
.swiper-container:hover {
  .btnBox {
    opacity: 1;
  }
}
</style>
